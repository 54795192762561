import '@css/app.css'
import Alpine from 'alpinejs'
import logoImage from '../img/logo-aantwerk.png'
import linkedinLogoImage from '../img/linkedin.png'
declare global {
    interface Window {
        Alpine: typeof Alpine;
        logoUrl: string;
        linkedinLogoUrl: string;
    }
}

window.Alpine = Alpine
Alpine.start()

window.logoUrl = logoImage
window.linkedinLogoUrl = linkedinLogoImage